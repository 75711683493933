.editProfile {
    // margin-top: 50px;

    .Profile_top_images {
        border-radius: 100%;
    }

    .section1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px;

        .btns {
            display: flex;
            justify-content: space-between;
            width: 25%;

            .cancelBtn {
                width: 45%;
                font-weight: 500;
            }
        }
    }

    .submit {
        // text-align-last: right !important;
        text-align: right !important;
    }

    .doneBtn {
        background-color: #25b8cb;
        color: #ffffff;
        width: 10%;
        padding: 0px 20px;
        //   width: 50%;
        text-align: center;
        //   justify-content: right;
        font-weight: 500;
    }

    .section2 {
        border: 1px solid #E2E2E2;
        border-radius: 8px;
        padding: 20px 40px;

        .profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px 0px;

            .changePhoto {
                margin: 10px 0px;
                color: #25B8CB;
                font-weight: 700;
                cursor: pointer;
            }
        }

        .services {
            display: flex;
            align-items: center;
            margin: 20px 0px;
            cursor: pointer;
            gap: 15px;
        }
    }

    .servies_list {
        margin: 30px 0px;
    }
}