.sharePostContainer {
  margin: 50px 0px;

  .top_heading {
    display: flex;
    justify-content: space-between;
  }
  .imagessection {
    display: flex;
    gap: 20px;
  }
  .profilePhoto {
    border-radius: 50%;
  }

  .noBorderOnFocus {
    font-size: 16px;
    font-weight: 600;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .cancelBtn {
    border: 0.2px solid black;
    background-color: white;
    color: black;
    padding: 4px;
    width: 120px;
    border-radius: 8px;
    font-weight: 600;
    margin-right: 20px;
  }

  .postBtn {
    border: none;
    background-color: #25b8cb;
    color: white;
    padding: 5px;
    width: 120px;
    border-radius: 8px;
    font-weight: 600;
  }

  .sharePosts {
    border: 1px solid #e2e2e2;
    padding: 30px;
    margin: 30px 0px;
    border-radius: 8px;

    .text_area {
      margin: 30px 0px;
    }

    .profile {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      .name {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
      }
    }

    .preview {
      .previewImgContainer {
        width: 200px;
      }

      // .previewImage{
      //   width: 100px;
      // }
    }
  }
}
