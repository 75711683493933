.Login {
  .Radio_btn {
    margin-top: 20px;
    display: flex;
    width: 100%;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: black !important;
    border: 2px solid black !important;
  }
  .input_custom {
    margin-right: 10px;
    width: 100%;
  }
  h3 {
    margin: 20px 0px;
    color: #333333;
    font-weight: 600;
  }
  .para {
    color: #424259a3;
    width: 60%;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-radio-wrapper .ant-radio  {
    position: absolute;
    right: 0;
    padding-right: 10px;
  }
  .input_custom {
    background-color: #f4f5f6;
    padding: 8px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    position: relative !important;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-group {
    width: 100%;
    display: flex;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper {
    width: 50%;
    position: relative;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    background: black !important;
    border: none !important;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper
    .ant-radio-inner {
    border: 2px solid black;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    padding-right: 10px;
  }
  .Radio_btn {
    margin: 20px 0px;
  }
  .btn_theme {
    border-radius: 8px !important;
    padding: 10px 0px;
    height: initial !important;
    // margin: 20px 0px !important;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-form-item {
    margin: 10px 0px !important;
  }
  .forgot_pass {
    text-align: center;
    color: #424259cc;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    p {
      width: 100%;
    }
  }
  .bottom_text {
    text-align: center;
    margin: 20px 0px;
    // position: absolute;
    // bottom: 0;
    justify-content: center;
  }
}
