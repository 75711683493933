.createAccount {
  .lable {
    color: #4242597a;
    font-weight: 500;
    margin: 10px 0px;
  }
  .profileForm {
    background-color: #f4f5f6;
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 10px;
    border-radius: 8px;
    margin: 20px 0px;
    align-items: center;
    .profiletop {
      display: flex;
      place-items: center;
      .Profile_top_images {
        height: 80px;
        width: 80px;
      }
    }
  }
  .custom_file_upload input[type="file"] {
    display: none !important;
  }
  .btn_theme {
    border-radius: 8px !important;
    padding: 10px 0px;
    height: initial !important;
  }
  @media (max-width: 767px) {
  }
}
