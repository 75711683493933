.service {
  margin: 20px 0px;
    .serviceDetails {
      width: 40%;
      // gap: 20px;
      display: flex;
      justify-content: space-between;
      .serviceCharge {
        color: #25b8cb;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }