.albumPage {

    .photoGrid,
    .reelGrid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
        margin: 10px;
    }
.uploadpicture{
    cursor: pointer;
}
    .photoContainer,
    .reelContainer {
        position: relative;
        width: 100%;
        // height: 180px;
        overflow: hidden;

        .photo,
        .reel {
            width: 100%;
            height: 115px;
            margin: 10px 0px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
    }

    .uplodpicture {
        font-weight: 600;
        color: #25B8CB;
    }
}