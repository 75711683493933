.Verify {
  width: 100%;
  .leftSide {
    width: 100%;
    .loginImg {

      height: 90vh;
      // object-fit: cover;
      object-fit: fill;
      width: 100%;
    }
    .imageOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(89.74deg, #06393F 0.22%, rgba(10, 67, 75, 0.95) 30.59%, rgba(18, 92, 101, 0) 99.78%);

      // background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    }
  }

  .rightSide {
    width: 100%;
    padding: 10px 40px;
    .logo {
      margin: 20px 0px;
    }
    .para {
      width: 60%;
      font-size: larger;
      color: #424259;
      opacity: 0.5;
    }
    .form {
      width: 75%;
    }
  }
}
