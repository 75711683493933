.FooterSection {
    background-color: var(--primarycolor);
    padding: 20px 0px;
    .FooterMainSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
        .Section2 {
            display: flex;
            gap: 15px;
            color: white;
            h6 {
                cursor: pointer;
                margin: 0 !important;
            }
        }
        .Section3 {
            display: flex;
            justify-content: center;
            gap: 10px;
            img {
                cursor: pointer;
            }
        }
    }
    .line {
        border-bottom: 0.1px solid rgb(245, 241, 241);
        margin: 10px 0px;
    }
    .FooterBottom {
        color: white;
        display: flex;
        justify-content: space-between;
        .cursor {
            cursor: pointer;
        }
    }
    @media (max-width:767px) {

        .FooterMainSection{
            display: block;
            .Section2{
                display: block;
                // margin: 10px 0px;
                h6{
                    padding: 10px 0px;
                }
            }
            .Section3{
                margin: 10px 0px;
            }
        }
        .FooterBottom{
            display: block ;
        }
    }
}