.explore {
  .top_heading {
    display: flex;
    justify-content: space-between;

    h2 {
      font-style: Poppins;
      color: #333333;
      font-weight: 600;
    }
  }

  .createPostBtn {
    background-color: #25b8cb;
    color: white;
    width: 15%;
    padding: 5px;
    border: none;
    font-weight: 600;
    border-radius: 8px;
  }

  .Stories_section,.Feed_section {
    margin: 20px 0px;
  }

}