.Authlayout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Background color for the entire login component */
  .Authlayout_Main {
    display: flex;
    width: 100%;
    background-color: #fff; /* Background color for the main login container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    .leftSide {
      width: 60%;
      position: relative;
      // flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      .loginImg {
        height: 100dvh;
        width: 100%;
      }
      .imageOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          89.74deg,
          #06393f 0.22%,
          rgba(10, 67, 75, 0.95) 30.59%,
          rgba(18, 92, 101, 0) 99.78%
        );
        z-index: 1;
      }
      .loginImg {
        position: relative;
        z-index: 0;
      }
    }
    .rightSide {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px 30px;
      height: 100dvh;
      overflow: scroll;
    }
    .rightSide::-webkit-scrollbar{
      display: none;
    }
    
  }
}
@media (max-width: 767px) {
  .leftSide {
    display: none !important;
  }
}
