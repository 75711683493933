.profileDetails {
  padding: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  .photoContainer{
    cursor: pointer;
  }
  .text {
    color: #5C5C5C;
    font-size: 16px;
    line-height: 1.5;
    font-family: Poppins;
  }

  .PostSection {
    h6 {
      // margin: 20px 0px;

    }
  }

  .service {
    .serviceDetails {
      // width: 20%;
      display: flex;
      gap: 20px;

      // justify-content: space-between;
      .serviceCharge {
        color: #25b8cb;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .photos,
  .reels {
    margin-top: 20px;

    .section1 {
      display: flex;
      align-items: center;
      gap: 10px;

      // justify-content: space-between;
      small {
        font-weight: 600;
        color: #25B8CB;

        svg {
          vertical-align: middle;
        }
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .photoGrid,
    .reelGrid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      margin-top: 10px;
    }

    .photo_title {
      display: flex;
      gap: 5px;
      font-size: 12px;
      /* font-size: smaller !important; */
      font-weight: 700;
    }

    .photoContainer,
    .reelContainer {
      position: relative;
      width: 100%;
      // height: 180px;
      overflow: hidden;

      .photo,
      .reel {
        width: 100%;
        height: 115px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .overlay {
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 8px 10px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      text-align: center;
    }

    .reelIcon {
      position: absolute;
      bottom: 20px;
      left: 30px;
      margin: 10px;
      width: 20px;
      height: 20px;
    }
  }


  .posts {
    margin-top: 20px;

    .postContainer {
      background-color: #F6FAFF;
      padding: 10px;
      margin-bottom: 20px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .interactionSection {
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 20%;
        justify-content: space-between;

        .likeSection {
          display: flex;
          align-items: center;
        }

        .likeIcon {
          font-size: 20px;
          margin-right: 5px;
        }

        .likeCount {
          font-size: 14px;
        }
      }

      .actionsContainer {
        display: flex;
        justify-content: space-between;
        /* Space out actions evenly */
        align-items: center;
        margin-top: 15px;
        width: 35%;

        .action {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }

        .actionIcon {
          font-size: 20px;
          /* Adjust icon size as desired */
          color: #5C5C5C;
          /* Set icon color */
        }

        .actionText {
          font-size: 14px;
          color: #585C60;

          margin-top: 5px;
        }
      }


    }
  }

  .reviews {
    .addreview {
      display: flex;
      align-items: center;

    }

    .starIcon {
      color: gold;
      margin-right: 5px;
    }
  }
}