.serviceForm {

  // margin: 50px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;

    // margin-bottom: 20px;
    .savebtn {
      border: none;
      background-color: #25B8CB;
      color: white;
      padding: 5px;
      width: 150px;
      border-radius: 8px;
      font-weight: 600;

    }
  }

  .form {
    .headersection {
      padding: 15px;
      background-color: #f4f5f6;

      .text {
        margin-left: 45px;
        font-size: 16px;
        font-weight: 300;
        margin: 5px 0px;
      }

      .uploadImageSection {
        display: flex;
        align-items: center;

        .btns {
          display: flex;
          width: 50%;

          .uploadbtn {
            background-color: #f2eff8;
            border-radius: 6px;
            // padding: 5px;
            width: 35%;
            font-weight: 600;
            border: 2px solid #333333cc;
            margin-right: 20px;
            margin-left: 20px;
          }

          .removebtn {
            background-color: #f2eff8;
            border-radius: 6px;
            padding: 5px;
            width: 25%;
            font-weight: 600;
            border: 2px solid #333333cc;
          }
        }
      }
    }

    .inputSection {
      margin: 10px 0px;

      .inputGroup {
        margin: 10px 0px;
      }

      .inputs {
        background-color: #F4F5F6;
      }
    }
  }
}