.Navbar {
    background: #25B8CB;
    .navbar-expand-lg .navbar-collapse {
        justify-content: right !important;
      }
    .ButtonTheme2 {
        // background-color: white;
        border: 1px solid white;
        border-radius: 8px;
        padding: 8px;
        color: white !important;
        font-weight: 500;

    }
    .ButtonTheme2:hover {
        background-color: white;
        border-radius: 8px;
        padding: 8px;
        color: black !important;
        font-weight: 500;

    }
    .profilePhoto{
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}