.counslor {
    .card {
        border-radius: 16px;
        margin: 2rem 0px;

        .card-body {
            padding: 2rem;
        }
    }

    .top {
        display: flex;
        align-items: center;
        gap: 20px;

        .profilePhoto {
            border-radius: 50%;
        }
    }

    .aboutus {
        margin: 10px 2px;
        color: #5C5C5C;
        font-style: Poppins;
    }

    .main_counslor {
        margin: 30px 0px;

        .top_heading {
            display: flex;
            justify-content: space-between;

            .show {
                cursor: pointer;
            }
            .show:hover{
                background-color: #8dadb2;
                height: fit-content;
                width: fit-content;
                opacity: 0.5;
                padding: 1px 3px;
                border-radius: 7px;
            }
        }

        h1 {
            margin: 20px 0px;
            font-size: 30px;
            font-weight: 600;
            color: #333333;
        }
    }
}