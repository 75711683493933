.availability {
  margin: 50px auto;
  .formGroup {
  
    label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
  }
  
  .timeSlot {
    display: flex;
    align-items: center;
  
    .ant-picker {
      margin-right: 10px;
    }
  
    button {
      margin-left: 10px;
    }
  }
  .profilePhoto{
    border-radius: 50%;
  }
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e2e2e2;
    width: 300px;
    padding: 30px;
    border-radius: 8px;
    .name {
      margin-top: 20px;
      font-weight: 700;
    }
  }
  .secondColumn {
    border: 1px solid #e2e2e2;
    padding: 20px;
    border-radius: 8px;
  }
  .availabilityRow {
    :where(.css-dev-only-do-not-override-42nv3w).ant-select-single {
      width: 100% !important;
      margin: 20px !important;
    }
    // padding: 20px;
    .dayInput {
      margin: 20px auto;
      background-color: #f4f5f6;
    }
    .timePicker {
      width: 100%;
      background-color: #f4f5f6;
      margin: 10px 0;
    }
  }
  .availabilitySection {
    padding: 10px 20px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    .savebtn {
      border: none;
      background-color: #25b8cb;
      color: white;
      padding: 5px;
      width: 150px;
      border-radius: 8px;
      font-weight: 600;
    }
  }
  .addButton {
   cursor: pointer;
   font-weight: 500;
   color: #25b8cb;
   margin: 10px 0;
  }
  .radios{
    margin-top: 20px;
    padding: 0px 20px;
    .radioInputs{
      margin-bottom: 20px;
    }
  }
}
