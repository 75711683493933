
.RequestPage{
  h4{
    margin: 20px 0px;
  }
  .newRequestCard {
    background-color: #f4f5f6;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 20px;
    .profileContainer {
      display: flex;
      justify-content: space-between;
    }
    .profile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 25%;
      .name{
        margin-left: 20px;
      }
    }
    .date {
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .requestBtn {
      background-color: #025b66;
      color: #ffffff;
      border-radius: 16px;
      font-weight: 500;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      width: 35%;
      .rejectBtn {
        width: 45%;
        font-weight: 500;
      }
      .acceptBtn {
        background-color: #25b8cb;
        color: #ffffff;
        width: 45%;
        font-weight: 500;
      }
    }
  }
}