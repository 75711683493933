.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button_theme {
  background: #25B8CB !important;
  border: none !important;
  padding: 10px 20px;
}

.ButtonTheme {
  background-color: #25B8CB;
  border-radius: 8px;

  .buttoncolor {
    color: white !important;
  }
}

.accordion-header {
  padding: 3px;
}

.accordion-item {
  border: none !important;
  /* margin: 10px 0px; */
}

.accordion-button:not(.collapsed) {
  color: #25B8CB;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 600;
}

.accordion-button {
  border: 0px !important;
}

.nav-link {
  color: white !important;
  font-weight: 500;
  padding: 0px 15px !important;
}

:where(.css-dev-only-do-not-override-42nv3w).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background: #F4F5F6 !important;
}

.show>.btn-primary.dropdown-toggle {
  background-color: transparent !important;
  border: none !important
}

.show>.btn-primary.dropdown-toggle:focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.btn-primary {
  background-color: transparent !important;
  border: none !important
}

.navbar-nav {
  align-items: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.edit_profile_btn {
  border: 2px solid #25B8CB !important;
  border-radius: 20px;
  padding: 4px 12px;
  color: #25B8CB !important;
  display: block;
  margin: 10px auto;
  width: 60%;
}

.edit_profile_btn:hover {
  border: 2px solid #25B8CB !important;
  background-color: #25B8CB !important;
  color: white !important;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 10px auto;
  width: 60%;
}

.btn_theme:hover {
  border: 1px solid #25B8CB !important;
  border-radius: 20px;
  padding: 4px 12px;
  color: white !important;
  margin: 10px 0px;
  width: 100%;
}

.btn_theme {
  display: block;
  margin: 10px 0px;
  border: 1px solid #25B8CB !important;
  background-color: #25B8CB !important;
  color: white !important;
  border-radius: 20px;
  padding: 4px 12px;
  width: 100%;
}

.Follow_massage_btn {
  display: flex;
  gap: 10px;
  width: 100%;
}

.follow_btn {
  border: 2px solid #25B8CB !important;
  border-radius: 20px;
  padding: 4px 12px;
  color: #25B8CB !important;
  margin: 10px auto;
  width: 50%;
}

.follow_btn:hover {
  border: 2px solid #25B8CB !important;
  background-color: #25B8CB !important;
  color: white !important;
  border-radius: 20px;
  padding: 4px 12px;
  margin: 10px auto;
  width: 50%;
}