.bookingCard {
  background-color: #f4f5f6;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 20px;

  .profileContainer {
    display: flex;
    justify-content: space-between;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .date {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .newRequestBtn {
    background-color: #025B66;
    color: white;

  }

  .upcomingBtn {
    background-color: #E86D27;
    color: white;
  }

  .completedBtn {
    background-color: #3C8E16;
    color: white;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 25%;

    .rejectBtn {
      width: 45%;
      font-weight: 500;
    }

    .acceptBtn {
      background-color: #25b8cb;
      color: #ffffff;
      width: 45%;
      font-weight: 500;
    }
  }
}

.starIcon {
  color: gold;
  margin-right: 5px;
}

.date_time {
  display: flex;
  gap: 5px;
}

.Appointment_profile_picture {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.section1 {
  .professionals {
    width: 250px;
    padding: 15px 0;
    /* border-radius: 10px; */
    /* border: 1px solid #e2e2e2; */
    /* text-align: center; */
    margin: 0 auto;

    .professionalsProfile {
      display: flex;
      margin: 30px 0;
      padding: 10px;
      background-color: #F4F5F6;
      border-radius: 10px;
    }
  }
}
.Date_time_section{
  display: flex;
  gap: 5px;
  align-items: center;
}