.CommonBanner {
    .bannerContainer {
        position: relative;
        width: 100%;
        height: 200px;
        /* Adjust height as needed */
        background-size: cover;
        background-position: center;
        color: white;
        // text-align: center;
        display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        /* Adjust opacity as needed */
        background: linear-gradient(89.74deg, #06393F 0.22%, rgba(10, 67, 75, 0.95) 30.59%, rgba(18, 92, 101, 0) 99.78%);
    }
    .bannerText {
        width: 30%;
        position: absolute;
        top: 35%;
        z-index: 1;
        font-size: 24px;
        h3{
            font-weight: 700;
        }
        h6{
            font-weight: 400;
        }
       
    }
   @media (max-width:767px) {

    .bannerText{
        width: 95%;
        text-align: center;
        h3{
            font-size: xx-large;
        }
    }
   }
}