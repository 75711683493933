.Post {
  // padding: 10px 0px;
  .Post_inner {
    background-color: #f6faff;
    margin: 20px 0px;
    padding: 20px 0px;
  }
  .profileimage{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .top {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .top_inner {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .hours {
      color: #585c60;
    }
  }
  .ImageSection,
  .contentSection {
    // margin: 20px 0px;
    .post_img {
      // width: 100%;
      // height: 450px;
      width: 100%;
      height: 310px;
    }
  }
  .contentSection {
    // margin: 10px 0px;
    padding: 10px;
  }
  .media {
    // padding: 0px 20px;
    padding: 0px 10px;
    .hit_media_section {
      display: flex;
      gap: 30px;
      text-align: center;
      align-items: center;
      // margin: 20px 0px;
      .rotated-icon {
        // transform: rotate(280deg);
      }
    }
    .socilmediaSection {
      display: flex;
      gap: 30px;
      text-align: center;
      // margin: 20px 0px;
      .Like {
        cursor: pointer;
      }
    }
  }
}
