.ProfileCard {
  .ProfileCard_inner {
    text-align: center;

    .profilePhoto {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    h5 {
      margin: 5px 0px;
    }

    .post_follower_following {
      text-align: center;
      justify-content: center;
      display: flex;
      gap: 30px;
    }
  }
  }
    .mdm_btn{
     margin: 10px 5px;
     width: 30%;
   gap: 10px;
    }

    .mdm_btn:hover{
      margin: 10px 5px;
      width: 30%;
    gap: 10px;
     }
.Following {
  .edit_profile_btn {
    width: 100%;

  }

  .card{
    margin: 20px 0px;
    border-radius: 10px;
  }
  .Following_detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    h6{

      font-weight: 700;
      }
  }
  .followin_picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 5px;
  }
}
