.Showreel {
  text-align: -webkit-center;
  margin: 20px 0px;
   
.app__videos{
  position: relative;
  height: 85vh;
  background-color: white;
  width: 70%;
  border-radius: 20px;
  max-width: 450px;
  max-height: 1200px;
  overflow:scroll;
  scroll-snap-type: y mandatory;
}

.app__videos::-webkit-scrollbar{
  display: none;
}

.app__videos{
  -ms-overflow-style: none;
  scrollbar-width: none;
}
  .videoContainer {
    position: relative;
    width: 414px;
    height: 712px;
    margin: 10px 0px;
  }

  .videoCard__player {
    background-color: rgb(29, 29, 29);
    height: 98%;
    width: 96%;
    // height: 712px;
  }

  .videoButtons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
  }

  .videoButtons button {
    margin-bottom: 10px;
  }
  .videofooter {
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: left;
    h6{
      width: 90%;
    }
    .namedate {
      margin: 10px 0px;
      display: flex;
      gap: 10px;
      h6{
        width: auto !important;
      }
    }
    span {
      font-size: 13px;
      color: #ffffff99;
    }
  }
}
