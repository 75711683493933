.Signup {
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper {
    position: relative;
    background: #f4f5f6;
    padding: 18px 50px;
    width: 45% !important;
    text-align: center;
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    top: 0 !important;
    padding-right: 10px;
    margin-top: 20px;

  }
  :where(.css-dev-only-do-not-override-3rel02).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: black !important;
    border: none !important;
  }
  h3 {
    margin: 20px 0px;
    color: #333333;
    font-weight: 600;
  }
  .para {
    color: #424259a3;
    width: 60%;
  }
  .signupImg {
    // width: 45%;
    width: 100%;
  }
  .RadioImages {
    margin: 30px 0px;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    background: black !important;
    border: none !important;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper
    .ant-radio-inner {
    border: 2px solid black;
  }
  :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    top: 0 !important;
    padding-right: 10px;
    margin-top: 20px;
  }
  
  .ant-space-item {
    text-align: center;
  }
  .btn_theme {
    border-radius: 8px !important;
    padding: 10px 0px;
    height: initial !important;
    // margin: 20px 0px !important;
  }
  @media (max-width: 767px) {
    :where(.css-dev-only-do-not-override-17seli4).ant-radio-wrapper {
      width: 70%;
    }
    .para {
      width: 100%;
    }
  }
}
